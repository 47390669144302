<template>
    <!-- Component contains land acknowledgement and contact information -->
    <div class="container bg-blackcontact w-full flex flex-col justify-center font-geologica xl:px-36 2xl:px-80">
        <div class="space-y-8">
            <!-- Land Icon Placeholder-->
            <div class="flex pt-8 md:flex-row flex-col ">
                <img src="@/assets/images/feather_logo.svg" class="feather-logo ">
                
                <p class="text-xl text-white md:hidden pt-8 pb-4" >Land Acknowledgement</p>
                <div class="items-center flex">
                <p class="text-xl text-white">The Trust is grateful to operate on the unceded, 
                    traditional territories of the Ktunaxa, Secwépemc, Sinixt, Syilx and Lheidli T'enneh Nations.
                </p>
            </div>
            </div>
            <hr>
            
            <!-- Mobile View -->
      
            <div class="space-y-4 md:hidden">
                <h3 class ="text-blue-6 text-3xl md:text-2xl font-normal">CONTACT INFO</h3>
                <p class="text-xl md:text-base text-white">Selkirk Innovates</p>
                <p class="text-xl md:text-base text-white">innovate@selkirk.ca</p>
            </div>
            <hr class="md:hidden">

            <!-- Selkirk and Trust Logo -->
            
            <div class="flex justify-between md:hidden">
                <div>
                    <a href="https://selkirk.ca/innovates" target="_blank">
                        <img class= "h-16" src="@/assets/images/selkirk_logo_white.svg" alt="Selkirk College: Selkirk Innovates">
                    </a>
                </div>

                <div>
                    
                    <img class=" h-16" src="@/assets/images/trust_logo_white.svg" alt="Columbia Basin Trust Logo">
                </div>
            </div>
           
            <!-- Desktop View -->
            <div class="hidden md:block">

                <div class="grid grid-cols-4 justify-start items-center">

                    <div>
                        <img class="w-auto h-16" src="@/assets/images/trust_logo_white.svg" alt="Columbia Basin Trust Logo">
                    </div>

                    <div>
                        
                        <a href="https://selkirk.ca/innovates" target="_blank">
                            <img class="w-auto h-16" src="@/assets/images/selkirk_logo_white.svg" alt="Selkirk College: Selkirk Innovates">
                        </a>
                    
                    </div>

                    <div></div>

                    <div class="space-y-4 md:block hidden">
                        <h3 class ="text-blue-6 text-3xl md:text-2xl font-normal">CONTACT INFO</h3>
                        <p class="text-xl md:text-base text-white">Selkirk Innovates</p>
                        <p class="text-xl md:text-base text-white">innovate@selkirk.ca</p>
                    </div>

                </div>


            </div>
        </div>
    </div>

</template>

<script>

export default {

   
}


</script>

<style>

.container {

    @apply pl-10 pr-10 pb-10;
   
    min-width: 100%;
}

.feather-logo {

    height: 100px;
    width: 100px

}
</style>