<template>

    <!-- Component that takes the user to the About Page -->
    <div class="hme p-8 flex flex-col font-geologica">

        <!--BC Map-->
        <div class="block md:hidden">
            <img src="@/assets/images/bc_map_mobile.png" class="w-1/2">

        </div>

        <div class="space-y-4 2xl:pr-56">

            <!--Title-->
            <h3 class="text-left text-3xl font-normal text-blue-7 leading-tight">
                Taking Action in the Columbia Basin
            </h3>
       
            <!--Desc-->  
            <p class="tracking-wider text-black text-xl leading-tight">
                The Columbia Basin Climate Source supports residents and communities to take climate action and become more resilient to climate change impacts.
            </p>

            <!--Button-->
            <div class="flex justify-end md:justify-start md:pt-8">
                <router-link
                    to ="/about"
                    class="btn-about bg-blue-8 hover:bg-sky-900 text-white text-center tracking-wide p-2 rounded-3xl text-xl "
                    tag="button">
                        About Us
                </router-link>
            </div>
        </div>

    </div>

</template>

<script>
export default {


}
</script>

<style>

.hme {

    min-height: 480px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .btn-about {

        width: 50%;     
        max-width: 50%;     
     
    }
}

.img {

    height: 100px;
    width: 100px
}
</style>