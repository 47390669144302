<template>

    <!-- Creates the navigation tabs seen on the nav menu -->
    <div class="nav-tab w-full" >
        <div class="text-white flex cursor-pointer items-center justify-center" @click="toggleOpened">
            <div class="flex-1 font-tabs font-geologica truncate lg:text-lg text-md pr-2">{{ name }}</div>
            <div class="nav-tab-icon " :class="{ 'nav-tab-icon-open': isOpened }"></div>
        </div>
    </div>

</template>

<script>

export default {

    props: {
        
        name: {

            type: String,
            required: true

        },

        isOpened: {

            type: Boolean,
            required: true
        }


    },

    data () {
        return {
            opened: false,
            
        }
    },
    watch: {
        // Watch for route changes and reset if so
        $route() {
            this.opened = false; 
        },

    
    },
    methods: {

        // Sends message to nav menu about its state
        toggleOpened () {
            
            this.$emit('toggle')
        },

        
        
    }

}

</script>

<style>

.nav-tab-icon {
  width: 0;
  height: 0;
  border-top: 6px solid transparent; 
  border-left: 8px solid #006298; 
  border-bottom: 6px solid transparent; 
  transition: transform 0.2s ease-out; 
}

/* Icon Animation */


.nav-tab-icon-open {
  transform: rotate(90deg); /* Rotate the triangle when dropdown is open */
}

.font-tabs {

    font-weight: 700;
    text-transform: uppercase;
    color: black

}

</style>