<template>
    <div class="flex flex-col">
        <div>
            <TopBar/>
        </div>

        <nav ref="nav" :class="{'sticky top-0 w-full bg-white z-[999]': isFollowing}">
            <Navigation/>
        </nav>

        <div>
            <router-view />
        </div>

        <!-- LandPage for Desktop -->
        <div v-if="!isMobile" >
            <LandPage/>
        </div>

        <!-- LandPage for Desktop pages that are in mobile and are not the home page -->
        <div v-if="isMobile && $route.name !== 'home'" >
            <LandPage/>
        </div>
        
        <div>
            <FooterItem/>
        </div>
    </div>

</template>

<script>

import FooterItem from '@/components/FooterItem.vue'
import TopBar from '@/components/TopBar.vue'
import LandPage from '@/components/LandPage.vue'
import Navigation from '@/components/NavigationMenu.vue';
import localsolutionsImage from '@/assets/images/gardener.jpeg'
import climateScienceImage from "@/assets/images/climate_science_title_image.png" 
import homeImage from "@/assets/images/homepage_image.png" 
import climateprojectionsImage from "@/assets/images/climate_change_image.png" 
import contactImage from "@/assets/images/contact_title_image.png" 
import aboutImage from "@/assets/images/about_title_image.png" 
import actionsImage from "@/assets/images/bicycle.jpeg" 
import localimpactsImage from "@/assets/images/localimpacts.jpg" 
import climateHub from "@/assets/images/featuredprojects/Climate Hub.jpg"
import electricVehicle from "@/assets/images/featuredprojects/Electric Vehicle.jpg"
import fauEnergy from "@/assets/images/featuredprojects/Fauquier Energy.jpg"
import fireSmart from "@/assets/images/featuredprojects/Fire Smart.jpg"
import kickingHorse from "@/assets/images/featuredprojects/Kicking Horse.jpg"
import kootenayC from "@/assets/images/featuredprojects/Kootenay Conservation.jpg"
import lowerKootenay from "@/assets/images/featuredprojects/Lower Kootenay.jpg"
import regionalEnergy from "@/assets/images/featuredprojects/Regional Energy.jpg"
import revelstokeArts from "@/assets/images/featuredprojects/Revelstoke Arts.jpg"
import waterHub from "@/assets/images/featuredprojects/Water Hub.jpg"

export default {

    components: {
        FooterItem: FooterItem,
        TopBar: TopBar,
        LandPage: LandPage,
        Navigation: Navigation
    
    },
    data () {

        return {
            isFollowing: false,
            isMobile: false,
                
            // Create new image instance to preload image
            preloadImages: [
                localsolutionsImage,
                climateprojectionsImage,
                homeImage,
                climateScienceImage,
                aboutImage,
                contactImage,
                actionsImage,
                localimpactsImage,
                climateHub,
                electricVehicle,
                kickingHorse,
                lowerKootenay,
                kootenayC,
                fireSmart,
                fauEnergy,
                regionalEnergy,
                revelstokeArts,
                waterHub

            ],
        }

    },
    methods: {

        //Check if the app is in mobile view 
        isMobileView() {

            this.isMobile = window.innerWidth < 768;

        }, 

        //Handles the navigation scrolling effect
       
        handleFollow() {

            //Reference navigation bar element 
            const nav = this.$refs.nav;
            
            //Check if nav exists and if the offsetTop value is not undefined before making the bar follow
            if (nav && nav.offsetTop !== undefined) {
            // Checks if the scroll position pixels is greater than the pixels from the top of the document to the top of the nav bar
            //console.log('Window scrollY:', window.scrollY);
            //console.log('Nav offsetTop:', nav.offsetTop);
            
                if (window.scrollY >= nav.offsetTop) {
                    this.isFollowing = true;
                } else {
                    this.isFollowing = false;
                }
            }
            else {
                console.log('Nav element is not ready yet')

            }

            

        },
        preloadImagesList(preloadImages) {
            preloadImages.forEach((preloadImage) => {
                const img = new Image();
                img.src = preloadImage;
            })
        }
        
    },
    
  
    mounted() {

        //Listen window scroll and Update scroll state
        
        window.addEventListener('scroll', this.handleFollow);
        window.addEventListener('resize', this.isMobileView);
        this.handleFollow();
        this.isMobileView();

        // preload images
        this.preloadImagesList(this.preloadImages)

    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleFollow);
        window.removeEventListener('resize', this.checkIsMobile);
    },

}
</script>

<style scoped>


</style>