//import Vue from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/views/HomePage.vue'
/* Overrides push 
const originalPush = createRouter.prototype.push
createRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
*/

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'Home | Columbia Basin Climate Source' }
  },
  {
    path: '/localsolutions',
    name: 'Climate Risks Tool',
    component: () =>
      import(/* webpackChunkName: "actions" */ '@/views/LocalSolutions.vue'),
    meta: { title: 'Climate Local Solutions | Columbia Basin Climate Source' }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    title: 'Privacy',
    component: () =>
      import(/* webpackChunkName: "actions" */ '@/views/PrivacyStatement.vue')
  },
  {
    path: '/contactus',
    name: 'Contact',
    title: 'Contact',
    component: () =>
      import(/* webpackChunkName: "actions" */ '@/views/ContactUs.vue'),
    meta: { title: 'Contact Us | Columbia Basin Climate Source' }
  },
  {
    path: '/communities',
    name: 'communities',
    component: () =>
      import(/* webpackChunkName: "actions" */ '@/views/Communities.vue')
  },
  {
    path: '/tour',
    name: 'tour',
    component: () =>
      import(/* webpackChunkName: "actions" */ '@/views/VideoTour.vue')
  },
  {
    path: '/actions',
    name: 'Action & Impacts',
    component: () =>
      import(/* webpackChunkName: "actions" */ '@/views/ClimateAction.vue'),
    meta: { title: 'Climate Action | Columbia Basin Climate Source' }
  },
  {
    path: '/impacts',
    name: 'Lower Your GHGs',
    component: () =>
      import(/* webpackChunkName: "impacts" */ '@/views/TheLocalImpacts.vue'),
    meta: { title: 'Local Impacts | Columbia Basin Climate Source' },
    children: [
      {
        path: 'economy',
        name: 'impacts-economy',
        component: () =>
          import(/* webpackChunkName: "impacts" */ '@/views/impacts/Economy.vue')
      },
      {
        path: 'nature',
        name: 'impacts-nature',
        component: () =>
          import(/* webpackChunkName: "impacts" */ '@/views/impacts/Nature.vue')
      },
      {
        path: 'infrastructure',
        name: 'impacts-homes',
        component: () =>
          import(/* webpackChunkName: "impacts" */ '@/views/impacts/Infrastructure.vue')
      },
      {
        path: 'quality-of-life',
        name: 'impacts-qol',
        component: () =>
          import(/* webpackChunkName: "impacts" */ '@/views/impacts/QualityOfLife.vue')
      }
    ]
  },
  {
    path: '/climate-science',
    name: 'Climate Science',
    component: () =>
      import(/* webpackChunkName: "climate-science" */ '@/views/UnderstandingClimate.vue'),
    meta: { title: 'Climate Science | Columbia Basin Climate Source' }
  },
  {
    path: '/climate-projections',
    name: 'Projection Tool',
    component: () =>
      import(/* webpackChunkName: "climate-projections" */ '@/views/ClimateProjections.vue'),
    meta: { title: 'Climate Projections | Columbia Basin Climate Source' }
  },
  {
    path: '/about',
    name: 'Community, Actions & Impacts',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/AboutPage.vue'),
    meta: { title: 'About Us | Columbia Basin Climate Source' }
  },
  {
    path: '/about-data',
    name: 'about-data',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/AboutData.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Privacy.vue')
  },
  {
    path: '/feedback',
    name: 'feedback',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/Feedback.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  // Always scroll to top when navigating to a new page
  scrollBehavior() {
    return { top: 0 }
  }
})

// Change document title
router.beforeEach((to, from, next) => {
  console.log(to);
  document.title = to.meta.title;
  next();
});

// keep track of router changes to track
router.afterEach((to) => {
  if (window._paq) {
    window._paq.push(['setDocumentTitle', document.title]);
    window._paq.push(['setCustomUrl', to.fullPath]);
    window._paq.push(['trackPageView']);
  } 
});

// This is dumb, but there isn't much else of a choice
// the following keeps track of scroll positioning
/*
const scrollableElementId = 'scrollable'
const scrollPositions = Object.create(null)

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

// only gets fired when pressing a new button.. when NavigationDuplicated isnt happeneing, when $route is set or triggered
router.beforeEach((to, from, next) => {
  const element = document.getElementById(scrollableElementId)
  // console.log("router.beforeEach: ", from.name, to.name)
  if (element !== null) {
    scrollPositions[from.name] = element.scrollTop
  }

  if (!hasQueryParams(to) && hasQueryParams(from)) {
    router.push({ name: to.name, query: from.query })
  } else {
    next()
  }
})

router.afterEach((/* to, from ) => {
  const element = document.getElementById(scrollableElementId)
  if (element !== null) { element.scrollTop = 0 }
})

window.addEventListener('popstate', () => {
  const currentRouteName = router.history.current.name

  const element = document.getElementById(scrollableElementId)
  if (element !== null && currentRouteName in scrollPositions) {
    element.scrollTop = scrollPositions[currentRouteName]
  }
})*/

export default router
