<template>

    <!-- Top Bar at the top of each page that contains the Columbia Basin Trust and Selkirk Innovates Logo -->
    <div class ="bg-gray-300 px-4 py-2">
        
        <div class="flex justify-between items-center md:justify-end md:space-x-4">

            <!--trust logo-->
            <img src="@/assets/images/Trust_Logo_BLACK.png" alt="Columbia Basin Trust" class="cbt-logo ">

            <!--Selkirk logo-->
            <img src="@/assets/images/logo_selkirk-innovates.png" alt="Selkirk Innovates " class="selkirk-logo ">

        </div>

    </div>



</template>
  
<script>
export default {

  
}
</script>

<style scoped>
.selkirk-logo {

    height:28px;
    width: 200px

}

.cbt-logo {
    
    height: 25px;
    width: 125px

}

@media (max-width: 768px) {
    .cbt-logo {
    
        height: 25px;
        width: 125px

    }

    .selkirk-logo {

        height: 28px;
        width: 195px

    }
}

@media (max-width: 376px) {
    .cbt-logo {
    
        height: 25px;
        width: 105px

    }

    .selkirk-logo {

        height: 28px;
        width: 175px

    }
}

img {

    padding: 0;
    margin: 0;

}
</style>