//import Vue from 'vue'

import './matomo.js'
import 'intro.js/introjs.css'
import {createApp} from 'vue'
import AppHome from './AppHome.vue'
import router from './router'
import store from './store'
import '@/assets/css/tailwind.scss'
import VueTippy from 'vue-tippy'
//import VueMatomo from 'vue-matomo'

const app = createApp(AppHome)

// global register

app.use(router)
app.use(store)

router.beforeEach((to, from, next) => {
  console.log('Route Name:', to.name);
  next(); 
});

//Updated for Vue3

app.use(VueTippy, {
  
  placement: 'bottom',
  arrow: true,
  duration: [350, 200],
  theme: 'bcs'
})

/*
app.use(VueMatomo, {
  host: '//basinclimatesource.ca/analytics',
  siteId: 1,
  router: router,
  enableLinkTracking: true
})
*/

app.mixin({
  methods: {
    isMobileView() {
      if (window.innerWidth < 768) {
        return true
      } else {
        return false
      }
    }
  }
})

//outdated
//app.use(Embed);

app.mount('#app')