<template>

    <!-- Cards that navigate to the three pages: TheLocalImpacts.vue, LocalSolutions.vue, ClimateAction.vue -->
    <div  class="container w-full flex flex-col justify-center space-y-4 font-geologica relative">
        <img :src="backgroundImg" alt="Background Image" class="background-img"/>
        <div class="flex items-center justify-center">
            <h1 class="heading-text">{{ titleText }}</h1>
        </div>
        <div>

            <!-- @click = "handleClick" -->
            <router-link 
            :to ="routePage"
            tag="button" 
            class="btn-main border border-2 text-white tracking-wide py-1 px-4 lg:py-2 lg:px-6 rounded-3xl button-container btn-text text-base md:text-sm lg:text-base">
                {{ buttonText }}
            </router-link>

        </div>

    </div>

</template>

<script>
export default {
    props: {

        titleText: {

            type: String,
            required: true

        },

        buttonText: {

            type: String,
            required: true

        },

        backgroundImg: {
            type: String,
            required: true,

        },
        routePage: {
            type: String,
            required: true,

        }


    },

    methods: {

        handleClick() {
            this.$emit('card-button-clicked', this.buttonText)
        }
        // matomo

        // detect clicks on navigation menu
        //matomoTrackClimateCardClick(name) {
        // window._paq.push(['trackEvent', 'Home Page Climate Cards', 'Click', name])
        //}

    }
}
</script>

<style scoped>

.container {
    @apply pl-10 pr-10 pb-10;
    min-height: 346px;
    min-width: 100%;
  
}

.heading-text {

    @apply tracking-wide lg:text-3xl text-white md:text-xl text-3xl;

}

.background-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.btn-main {
    width: auto;    
    
}

.button-container {
  position: absolute;
  bottom: 25px;
  right: 25px;
}

@media (min-width: 768px) and (max-width: 914px) {

    .container {

        min-height:240px;
        aspect-ratio: 1;
    }



}

@media (min-width: 914px) {

    .container {

        min-height:0px;
        aspect-ratio: 1;
    }

}

</style>