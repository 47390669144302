<template>

    <!-- Creates the buttons -->
    <div>

      <div class="flex flex-col justify-around flex-1 pb-8">
        <div class="text-center space-y-2 flex flex-col ">
          <!--
          @click = "matomoTrackButtons('Local Impacts')"
          matomoTrackButtons('Climate Local Solutions')
          matomoTrackButtons('Climate Actions')
          matomoTrackButtons('Climate Projections')
          -->
          <p 
          class="text-3xl pb-4 "
          v-if="isVisibleText"
          
          >
            What else can I do to make a difference?

          </p>

          <!-- go to local impacts page -->
          <router-link 
            to="/impacts" 
            class="btn-main bg-lime-1 hover:bg-lime-500 text-white tracking-wide p-1 rounded-3xl text-xl leading-tight" 
            tag="button"
            v-if="isImpacts" @click = "matomoTrackButtons('Local Impacts')">
              Understand Local Impacts
          </router-link>

          <!-- go to actions page -->
          <router-link 
            to ="/actions" 
            class="btn-main bg-green-2 hover:bg-lime-600 text-white tracking-wide p-1 rounded-3xl text-xl leading-tight" 
            tag="button"
            v-if="isCanDo" @click = "matomoTrackButtons('Climate Actions')">
              Learn What YOU Can Do
          </router-link>

          <!-- go to local solutions page -->
          <router-link
            to ="/localsolutions"
            class="btn-main bg-green-3 hover:bg-green-medium text-white tracking-wide p-1 rounded-3xl text-xl leading-tight"
            tag="button"
            v-if="isSolutions" @click = "matomoTrackButtons('Climate Local Solutions')">
            Explore Local Solutions
          </router-link>

          <!-- go to climate projections page -->
           
          <router-link
            to ="/climate-projections" 
            class="btn-data bg-blue-7 hover:bg-blue-8 text-white tracking-wide p-1 flex items-center "
            tag="button"
            v-if="isData">
            <span class="ml-2" @click = "matomoTrackButtons('Climate Projections')">GO TO DATA</span>
            
            <img src="@/assets/images/arrow.png" class="arrow">

          </router-link>
     
          <!-- go to climate science page -->
          <router-link
          to ="/climate-science"
          class = "text-blue-8 underline underline-offset-1 font-geologica pt-4 font-bold pb-10"
          v-if="isClimate" @click = "matomoTrackButtons('Climate Science')"
          >

          Learn more about climate science


          </router-link>
    
        </div>
      
      </div>
    </div>

</template>

<script>

export default {

  props: {

    isVisibleText: {

      type: Boolean,
      default: true

    },

    isImpacts: {

      type: Boolean,
      default: true

    },

    isCanDo: {

      type: Boolean,
      default: true

    },

    isSolutions: {

      type: Boolean,
      default: true

    },

    isData: {

      type: Boolean,
      default: true

    },

    isClimate: {

      type: Boolean,
      default: true

      }
  },

  methods: {
    
    matomoTrackButtons(name) {
      window._paq.push(['trackEvent', 'Make A Difference Buttons', 'Click', name]);
    }
      
  }

}

</script>

<style scoped>

.btn-main {

@apply w-72 ml-auto mr-auto;

}

.btn-data {

  width: 10rem;      
  margin-left: auto;    
  margin-right: auto; 


}

.arrow {

  height: 30px;
  width: 30px;

}
</style>