<template>

  <!-- Footer for each page -->
  <div id="footer" class=" bg-normal-black text-center font-condensed h-8 flex items-center justify-center w-full px-2 ">
    <div class=" text-xs md:text-base">
      <p class="font-geologica text-gray-5">&copy; 2024 SELKIRK COLLEGE. ALL RIGHTS RESERVED.</p>
    </div>
  </div>
</template>

<script>

export default {

}
</script>

<style lang="scss">
#footer {
  // line-height: 30px;
  text-transform: uppercase;
  color: white;
  overflow: hidden;
  
}


</style>
