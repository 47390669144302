<template>

  <!-- Navigation Menu -->
  <div id="nav font-geologica" class="z-[999]">

      <!--If Mobile-->
      <div v-if="isMobile" class="flex items-center justify-between w-full" ref="mobileMenu" >

        <!--Title Text-->
     
      
        <div class="flex items-center ">
          <a href="/">
            <img src="@/assets/images/climate_source_logo.svg" class="climate-logo">
          </a>
          <div class="text-left leading-none text-blue-8">
            <a href="/">
              <p class="text-sm font-normal">Columbia Basin</p>
              <p class="text-lg font-normal">Climate Source</p>
            </a>
          </div>
        </div>

            <!--Search-->
        <div class="flex items-center pr-2 ">
          <!--Remove general search for now 
          <Search/>-->

          <!--Menu -->
          
          <button class="focus:outline-none" @click="toggleNav">
            <div class="w-8 h-1.5 bg-black my-1 rounded transition-transforms duration-300" 
            :class="{ 'transform-topbar': navOpen }"></div>
            <div class="w-8 h-1.5 bg-black my-1 rounded transition-opacity duration-300" 
                :class="{ 'opacity-0': navOpen }"></div>
            <div class="w-8 h-1.5 bg-black my-1 rounded transition-transforms duration-300" 
                :class="{ 'transform-botbar': navOpen }"></div>
          </button>
        
        </div>
        
        <!-- Toggle open/close of dropdown menu -> hidden if navOpen is false-->
     
        <div :class="{'hidden': !navOpen }" class=" absolute right-0 text-right w-44 shadow-lg z-10 overflow-y-auto rounded-tl-xl rounded-bl-xl mt-[23.25rem]"  >
          <!-- Menu -->
          <ul class="list text-right bg-white py-2 ">
            <!--
            @click = "matomoTrackNavigationClick('Home')"
            @click = "matomoTrackNavigationClick('About')"
            @click = "matomoTrackNavigationClick('Local Impacts')"
            @click = "matomoTrackNavigationClick('Climate Actions')"
            @click = "matomoTrackNavigationClick('Climate Local Solutons')"
            @click = "matomoTrackNavigationClick('Climate Projections')"
            @click = "matomoTrackNavigationClick('Climate Science')"
            @click = "matomoTrackNavigationClick('Contact')"
            -->
            <router-link to="/" >Home</router-link>
            <router-link to="/about" >About</router-link>
            <router-link to="/impacts" >Local Impacts</router-link>
            <router-link to="/actions" >Climate Actions</router-link>
            <router-link to="/localsolutions" >Local Solutons</router-link>
            <router-link to="/climate-projections" >Climate Projections</router-link>
            <router-link to="/climate-science" >Climate Science</router-link>
            <router-link to="/contactus" >Contact</router-link>
      
          </ul>

        </div>
          
      </div>
  
   
      <!--else Desktop-->

      <div v-else class="flex pl-4 pr-8 pt-2 z-[999] pb-2">

        <div class="flex w-full">
         
          <div class="flex items-center text-left leading-none text-blue-8">
            <a href="/">
              <img src="@/assets/images/climate_source_logo.svg" class="climate-logo">
            </a>
              <div class="text-left leading-none text-blue-8">
                <a href="/">
                  <p class="text-2xl">Columbia Basin</p>
                  <p class="lg:text-4xl md:text-3xl">Climate Source</p>
                </a>
              </div>
            
          </div>
            
        </div>

        <!-- About / Climate Action / Data & Maps Tab & Search / Contact-->
        <div class="flex flex-col justify-center">
        <!-- Removed contact us link in the top right of the menu-->
         <!--
          
          <div class="flex justify-end">
            
         
             <div class="flex items-center ">
              <router-link 
                to="/contactus" 
                class="font-bold text-xs"
                :class="{'underline-active': isContactActive}" >
                CONTACT US
            </router-link>
            </div>
          </div>
        -->
          <!-- Desktop Nav Tabs -->
           
          <div ref="dropdowns" class="flex justify-center items-center ">

            <!-- climate tab -->
            <div class="lg:pr-20 pr-6">
              <button class="no-focus" @click="toggleTabDropdown('climateTab')"
                :class="{'underline-active': isClimateActive}" >

                <NavTabs name="Take Action"
                :isOpened="openedTab === 'takeAction'"
                  @toggle="toggleTab('takeAction')"
                />

              </button>
            </div>

            <div v-if="tabDropdowns.climateTab" class=" absolute w-56 shadow-lg z-10 overflow-y-auto takeaction-tab border-[#006298] border-2">
              <ul class="list lg:text-base md:text-sm bg-white py-2 pl-2">

                <!--
                @click = "matomoTrackNavigationClick('Local Climate Solutions')"
                @click = "matomoTrackNavigationClick('Climate actions')"
                -->
                <router-link to="/localsolutions" @click="closeTabDropdown('climateTab')">Local Climate Solutions </router-link>
                <router-link to="/actions" @click="closeTabDropdown('climateTab')">Climate Actions </router-link>
                
              </ul>
            </div>
            
            <!-- data tab -->
            <div class="lg:pr-20 pr-6">
              <button class="no-focus" @click="toggleTabDropdown('dataTab')" 
              :class="{'underline-active': isDataActive}" >
                <NavTabs 
                name="Learn Why"
                  :isOpened="openedTab === 'learnWhy'"
                  @toggle="toggleTab('learnWhy')"
                />
              </button>
            </div>

            <div v-if="tabDropdowns.dataTab" class=" absolute w-48 shadow-lg z-10 overflow-y-auto learnwhy-tab border-[#006298] border-2">
              <ul class="list lg:text-base md:text-sm bg-white py-2">

                <!--
                @click = "matomoTrackNavigationClick('Local Impacts')"
                @click = "matomoTrackNavigationClick('Climate Projections')"
                @click = "matomoTrackNavigationClick('Climate Science')"
                -->
                <router-link to="/impacts" @click="closeTabDropdown('climateTab')">Local Impacts</router-link>
                <router-link to="/climate-projections" @click="closeTabDropdown('climateTab')">Climate Projections</router-link>
                <router-link to="/climate-science" @click="closeTabDropdown('climateTab')">Climate Science</router-link>
          
              </ul>
            </div>
            <!-- About Tab -->
            <div class="md:pr-2 pr-4">
              <button class="no-focus" @click="toggleTabDropdown('aboutTab')" 
                  :class="{'underline-active': isAboutActive}" >

                  <NavTabs  
                  name="About"
                  :isOpened="openedTab === 'about'"
                  @toggle="toggleTab('about')"
                  />

                </button>
              </div>

              <div v-if="tabDropdowns.aboutTab" class=" absolute w-44 shadow-lg z-10 overflow-y-auto about-tab border-[#006298] border-2"
              >
                <ul class="list lg:text-base md:text-sm bg-white py-2 ">
                  
                  <!--
                  @click = "matomoTrackNavigationClick('About Us')"
                  @click = "matomoTrackNavigationClick('Contact Us')"
                  -->
                  <router-link to="/about" @click="closeTabDropdown('aboutTab')" >About Us</router-link>
                  <router-link to="/contactus" @click="closeTabDropdown('aboutTab')">Contact Us</router-link>
                  <!--<router-link to="/privacy" @click="closeTabDropdown('privacyTab')">Privacy</router-link>-->

            
                </ul>

            </div>
          </div>
  
        </div>


      </div> 

      <!-- Home / {{ Page Name }}
      <div v-if="!isMobile">
        <div class="font-geologica pl-10 flex text-xs">
          <p class="underline "> Home</p>
          <p v-if="$route.name !== 'home'" class="px-2 ">/  {{ $route.name}}</p>
        </div>
      </div>-->
      
  </div>
    


</template>

<script>

//import SearchButton from '@/components/SearchButton.vue'
import NavTabs from '@/components/NavTabs.vue'

export default {
  components: {

    //Search: SearchButton,
    NavTabs: NavTabs

  },

  data () {

    return {

      isMobile: false,
      navOpen: false,
      tabDropdowns: {
        aboutTab: false,
        climateTab: false,
        dataTab: false
      },
      openedTab: null
      
    }


  },


  computed: {

    // Check if the path is active
    isContactActive(){

      return this.$route.path === '/contactus';

    },

    // About tab
    isAboutActive() {
      return this.$route.path === '/about'  || this.$route.path === '/contactus';
    },

    // Take action tab
    isClimateActive() {

      return this.$route.path === '/actions' || this.$route.path === '/localsolutions';

    },

    // Learn why tab
    isDataActive() {
      return this.$route.path === '/climate-projections' || this.$route.path === '/impacts' || this.$route.path === '/climate-science';
    },

  },

  methods: {

    //Toggle the navigation menu for mobile
    toggleNav() {

      //Open/close 
      this.navOpen = !this.navOpen;

      // Log if its opened or closed
      //console.log(`Menu is ${this.navOpen ? 'open' : 'closed'}`);

    
    },

    //Check if the app is in mobile view
    isMobileView () {

        //Check breakpoint
        this.isMobile = window.innerWidth < 768;
     
    },


    // Toggle the correct dropdown for the desktop nav tabs
    toggleTabDropdown(tabName) {

      
      Object.keys(this.tabDropdowns).forEach((key) => {
      if (key !== tabName) {
        this.tabDropdowns[key] = false;
          }
      })

      this.tabDropdowns[tabName] = !this.tabDropdowns[tabName]

      // Checks if true when opened and false when closed
      console.log(this.tabDropdowns[tabName])
    },

 
    // Close all dropdowns
    closeAllDropdowns() {
      this.tabDropdowns.aboutTab = false;
      this.tabDropdowns.climateTab = false;
      this.tabDropdowns.dataTab = false;
      this.navOpen = false; // Close the mobile nav as well if open
      this.openedTab = null
    },

    // Toggle or close the tab
    toggleTab(tabName) {
      this.openedTab = this.openedTab === tabName ? null : tabName; 
    },

    closeTabDropdown(tabName) {
      this.tabDropdowns[tabName] = false;
    },

    // Closes tabs if there is a click outside the tabs
    handleClickOutside(event) {
      const dropdowns = this.$refs.dropdowns; 
      if (dropdowns && !dropdowns.contains(event.target)) {
        this.closeAllDropdowns();
      }
    },

    // Close the mobile menu on outside click
    handleClickOutsideMobileNav(event) {
      if (this.navOpen && this.$refs.mobileMenu && !this.$refs.mobileMenu.contains(event.target)) {
        this.navOpen = false; 
      }
    },

    // matomo

    // detect clicks on navigation menu
    //matomoTrackNavigationClick(name) {
     // window._paq.push(['trackEvent', 'Navigation Menu', 'Click', name])
    //}
    
  },

  mounted () {

    //Listen to window resize and Update mobile state
    window.addEventListener('resize', this.isMobileView);
    document.addEventListener('click', this.handleClickOutside); // listen for clicking outside of dropdowns
    document.addEventListener("click", this.handleClickOutsideMobileNav);
    this.isMobileView();

    // Close all menus when navigating
    this.$router.beforeEach((to, from, next) => {
      this.closeAllDropdowns();
      next();
    });
  },

  beforeUnmount() {

    window.removeEventListener('resize', this.checkIsMobile);
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener("click", this.handleClickOutsideMobileNav);
  },

  


}

</script>

<style lang="scss" scoped>

#nav {
  padding-left: 0.1rem;
  padding-right: 0.1rem


}

// Menu style
.list {
  @apply flex flex-col space-y-4 text-left pl-4;
  
}

//item style
.item {
  @apply block md:inline-block px-4 py-2;
}

// control whether the menu is hidden or not
.hidden {

  display: none;

}

.climate-logo {

  height: 55px;
  width: 90px

}

.about-tab {

  margin-top: 152.5px;
  right: 200px

}


@media (min-width: 768px) {
  .about-tab {
    margin-top: 122.5px; 
    right: 43px; 
  }
}

@media (min-width: 1024px) {
  .about-tab {
    margin-top: 137.5px;
    right: 40px
  }
}


.learnwhy-tab {

margin-top: 152.5px;
right: 200

}


@media (min-width: 768px) {
.learnwhy-tab {
  margin-top: 160.5px; 
  right: 139.5px; 
}
}

@media (min-width: 1024px) {
.learnwhy-tab {
  margin-top: 177.5px;
  right: 203px
}
}


.takeaction-tab {


  margin-top: 175px;
  right: 400px;

}

@media (min-width: 768px) {
  .takeaction-tab {
    margin-top: 122.5px; 
    right: 277.5px; 
  }
}

@media (min-width: 1024px) {
  .takeaction-tab {
    margin-top: 137.5px; 
    right: 407.5px; 
  }
}

.transform-topbar {
  transform: rotate(40deg) ;
  transform-origin: left;
}

.transform-botbar {

  transform: rotate(-40deg) ;
  transform-origin: left;
  

}

.underline-active {

  text-decoration: underline; 
  text-decoration-color: #006298; 
  text-underline-offset: 4px; 

}


</style>
