<template>

  <div class='font-geologica '>

    <!--Navigation-->
    <!--Implemented scrolling feature where the nav menu follows as the user scrolls-->
 
    
    <!-- Home Page Title -->
    <section ref ="homeSection">
      <div class="bg-image">
        <div class="lg:pt-28 pt-4 sm:pt-12 md:pt-16 flex flex-col  h-full w-full font-geologica">
          <div class="hero md:flex md:justify-center flex-1">
            <div class="flex flex-col justify-center space-y-2 text-center">
              <h1 class=" text-center tracking-wide leading-tight font-semibold">MAKING A DIFFERENCE TOGETHER </h1>
              <h2 class="  text-center tracking-normal text-white leading-none font-normal">Act now for a climate resilient future.</h2>
            </div>
            
          </div>
          
          <!-- Component that contains the main buttons -->
      
          <MakeADifference class="pb-4" :isVisibleText="false" :isImpacts="true" :isCanDo="true" :isSolutions="true" :isData="true" :isClimate="false"/>
        </div>
        

      </div>

      <!-- Mobile View -->
      <!-- Display cards vertically if mobile-->
      <div v-if="isMobile">

        <section ref ="aboutMobileSection">
          <AboutUs/>
        </section>

        <ClimateCard 
          v-for = "card in climateCards"
          :key="card.id"
          :titleText="card.titleText"
          :buttonText="card.buttonText"
          :backgroundImg="card.backgroundImg"
          :routePage="card.routePage"
          
        
        />
        <!--  @card-button-clicked="matomoTrackClimateCardClick"  -->

        <!-- Land Acknowledgment -->
        <section ref = "contactSection" class="block md:hidden">

          <LandTab/>

        </section> 
        
      </div>

        <!-- Desktop View-->
        <div v-else >

          <!-- Second Row -->
          <div class="flex flex-col xl:px-72" >

            <div class ="flex ">
              
              <!--BC MAP Desktop-->
              <div class="flex-1">
                <img src="@/assets/images/region_map.png" class="w-4/5 pt-8 md:w-full">

              </div>

              <!--AboutUs Component -->
              <div class="flex-1 ">
                <AboutUs/>
              </div>

            </div>

          </div>

          <!-- Horizontal Line -->
          <div class="pl-10 pr-10 xl:px-36 2xl:px-80">
            <hr class="border-gray-500">
          </div>

          <!-- Third Row-->
         
          <div class="grid grid-cols-3 gap-10 p-10 2xl:px-80 xl:px-36">

            <ClimateCard 
              
              v-for = "card in climateCards"
              :key="card.id"
              :titleText="card.titleText"
              :buttonText="card.buttonText.toUpperCase()"
              :backgroundImg="card.backgroundImg"
              :routePage="card.routePage"

            />

          </div>
        
        </div>
      </section>
    </div>

      

</template>
<script>

//import Navigation from '@/components/NavigationMenu.vue'
import AboutUs from '@/components/AboutUs.vue'
import LandPage from '@/components/LandPage.vue'
import MakeADifference from '@/components/MakeADifference.vue'
import ClimateCard from '@/components/ClimateChangeCard.vue'

const meta = {
  title: 'Home',
  description: 'Welcome'
}

export default {
  name: 'home',
  metaInfo() {
    return {
      title: meta.title,
      meta: [
        { property: 'description', content: meta.description, vmid: 'description' },
        { property: 'og:description', content: meta.description, vmid: 'og:description' },
        { property: 'twitter:title', content: meta.title, vmid: 'twitter:title' },
        { property: 'twitter:description', content: meta.description, vmid: 'twitter:description' }
      ]
    }
  },

  components: {
    //Navigation: Navigation,
    AboutUs: AboutUs,
    LandTab: LandPage,
    MakeADifference: MakeADifference,
    ClimateCard: ClimateCard

  },


  data() {
    return { 

      //Set initial state to false
      isFollowing: false,
      isMobile: false,

      //Pass data through to the climate cards
      climateCards: [

        { 
          id: 1, 
          titleText: 'What are the local impacts of climate change?', 
          buttonText: 'Learn More',
          backgroundImg: require('@/assets/images/gateway/gateway-01.svg'),
          routePage: '/impacts'
        },
        { id: 2, 
          titleText: 'What can I do to make a difference?', 
          buttonText: 'Take Action',
          backgroundImg: require('@/assets/images/gateway/gateway-02.svg'),
          routePage: '/actions'
        },
        { 
          id: 3, 
          titleText: 'What are others doing to make a difference?', 
          buttonText: 'Explore Solutions',
          backgroundImg: require('@/assets/images/gateway/gateway-3.svg' ),
          routePage: '/localsolutions'
        },

      ]
    }
  
  },

  methods: {

    //Handles the navigation scrolling effect
    /*
    handleFollow() {

      //Reference navigation bar element 
      const nav = this.$refs.nav;

      //Checks if the scroll position pixels is greater than the pixels from the top of the document to the top of the nav bar
      if (window.scrollY >= nav.offsetTop) {
        this.isFollowing = true;
      } else {
        this.isFollowing = false;
      }

    },*/

    //Check if the app is in mobile view 
    isMobileView() {

      this.isMobile = window.innerWidth < 768;
   
    },

    // matomo

    //matomoTrackClimateCardClick(name) {
    //  window._paq.push(['trackEvent',, 'Climate Card Buttons', 'Click', name])
    //}


  },

  mounted() {

    window.addEventListener('resize', this.isMobileView);
    this.isMobileView();

    
  },

  beforeUnmount() {

    window.removeEventListener('resize', this.checkIsMobile);
  },
}

</script>

<style lang="scss" scoped>

h1 {
  color: white;
  font-size: 40px;
  
  
  // width: 330px;
}

h2 {

  color: white;
  font-size: 30px;


}

.hero {

  padding-bottom: 2vh;

}

.bg-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('@/assets/images/homepage_image.png'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    min-height: 500px; 
  
}

@media (max-width: 768px) { 
    .bg-image {
        min-height: 400px;
    }
}

</style>
